/*global window, document, getSiblings ,setInterval, clearInterval,getElements,getElement,getNextSibling,getPrevSibling,setAttributes,getComputedStyle,pageDirection,console*/
/*jslint es6 */
/*=== Theme Customize ===*/
document.addEventListener('DOMContentLoaded', function () {
    'use strict';

    /*=== mainslider ===*/
    var mainSlider = getElement('body:not(.wp-admin) .main-slider');
    if (mainSlider) {
        var swiper = new Swiper(mainSlider, {
            loop: true,
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        });
    }

    /*=== mainslider ===*/
    var langSlider = getElement('body:not(.wp-admin) .languages-slider');
    if (langSlider) {
        var swiper = new Swiper(langSlider, {
            slidesPerView: 9,
            spaceBetween: 30,
            loop: true,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                1024: {
                    slidesPerView: 7,
                    spaceBetween: 30,
                },
                768: {
                    slidesPerView: 5,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });
    }

    /*=== testimonials-slider ===*/
    var TestiSlider = getElement('body:not(.wp-admin) .testimonials-slider');
    if (TestiSlider) {
        var swiper = new Swiper(TestiSlider, {
            loop: true,
            navigation: {
                nextEl: '.testimonials-slider-next',
                prevEl: '.testimonials-slider-prev',
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
        });
    }

    /*=== our-clients-slider ===*/
    var cliSlider = getElement('body:not(.wp-admin) .our-clients-slider');
    if (cliSlider) {
        var swiper = new Swiper(cliSlider, {

            slidesPerView: 3,
            slidesPerColumn: 2,
            loop: true,
            navigation: {
                nextEl: '.our-clients-next',
                prevEl: '.our-clients-prev',
            },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });
    }

    /*=== our-clients-slider ===*/
    var certificateSlider = getElement('body:not(.wp-admin) .certificate-slider');
    if (certificateSlider) {
        var swiper = new Swiper(certificateSlider, {
            slidesPerView: 4,
            loop: true,
            spaceBetween: 30,
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });


    }

    //======> Tabs System <======//
    tabCallBack();

    /*=== our-clients-slider ===*/
    var breadcrumbSlider = getElement('body:not(.wp-admin) .breadcrumb .swiper-container');
    if (breadcrumbSlider) {
        var swiper = new Swiper(breadcrumbSlider, {
            loop: true,
            effect: 'fade',
            autoplay: {
                delay: 5500,
                disableOnInteraction: false,
            },
        });


    }

    const modalX = document.querySelector(".modal-box"),
          overlay = document.querySelector(".lodaing");

    modalX.style.display = "none";
    overlay.classList.add("lodaing-out");

    setTimeout(function () {
        modalX.style.display = null;
        overlay.classList.add("lodaing-out");
        overlay.remove();
    }, 3000);
});